import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import { NotFoundPageQuery } from "../../graphql/types"
import { mapMetadata } from "../core/mappers/map-metadata"
import { Page } from "../components/page/page"
import { PageProps } from "../types/page"
import { SearchPageHeader } from "../components/search-page-header/search-page-header"
import { FormattedHTMLMessage } from "../components/formatted-html-message/formatted-html-message"
import { Icon } from "../components/icon/icon"
import styles from "./404.module.scss"

export const NotFoundPage: FunctionComponent<PageProps<NotFoundPageQuery>> = ({
  data,
  ...props
}) => {
  const intl = data?.contentfulIntl?.values
  const metadata = mapMetadata(null, {
    title: intl?.header_title,
    description: intl?.header_body
  })

  return (
    <Page
      {...props}
      metadata={metadata}
      pageOptions={{ headerVariant: "cream", hideSearch: true }}
      fullHeight={false}
    >
      <SearchPageHeader title="404-header-title" subtitle="404-header-body" />
      <div className={styles.contentGrid}>
        <div className={styles.content}>
          <Icon variant={"24-chat"} />
          <h1>
            <FormattedMessage id="404-title" />
          </h1>
          <FormattedHTMLMessage id="404-body" />
        </div>
      </div>
    </Page>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    contentfulIntl(key: { eq: "404" }) {
      values {
        header_title
        header_body
      }
    }
  }
`
